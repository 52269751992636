<template>
  <div class="p-grid">
    <div class="p-col-12">
      <div class="card">
        <h3>
          {{ orderList.name }}
          <Button icon="pi pi-pencil" class="p-button-success p-mr-2" @click="editOrderList" />
          <Button icon="pi pi-trash" class="p-button-danger" @click="confirmDeleteOrderList" />
        </h3>
        <DataTable
            :value="orderList.items"
            editMode="cell"
            :loading="orderList.length && orderList.items.length === 0"
            rowGroupMode="subheader"
            groupRowsBy="product.category"
            :expandableRowGroups="true"
            v-model:expandedRowGroups="expandedRowGroups"
        >
          <template #groupheader="slotProps">
            <b>{{slotProps.data.product.category}}</b>
          </template>
          <Column field="product.category" header="Categorie"></Column>
          <Column field="product.name" header="Product"></Column>
          <Column field="quantity" header="Aantal">
            <template #body="{data}">
              <span style="cursor:pointer;"><b>{{data.quantity}}</b>
                <Button icon="pi pi-pencil" class="button-edit p-button-rounded p-button-success p-ml-2"/>
              </span>
            </template>
            <template #editor="slotProps">
              <InputNumber
                  v-model="slotProps.data[slotProps.column.props.field]"
                  :min="0"
                  @update:modelValue="onQuantityEdit($event, slotProps)"
                  showButtons
                  buttonLayout="horizontal"
                  incrementButtonIcon="pi pi-plus" decrementButtonIcon="pi pi-minus"
                  locale="nl-NL" />
            </template>
          </Column>
          <Column>
            <template #body="slotProps">
              <Button icon="pi pi-trash" class="p-button-rounded p-button-warning"
                      @click="confirmDeleteOrderListItem(slotProps.data)"/>
            </template>
          </Column>
          <template v-if="orderList.items && orderList.items.length" #footer>
            <div class="p-grid p-jc-between">
              <div class="p-col-12">
                <Button label="Bestellen" icon="pi pi-check" class="p-mr-2 p-button-success" :disabled="newOrderDisabled" @click="newOrder" />
                <Button label="Product toevoegen" icon="pi pi-download" @click="$router.push({ name: 'products' })" />
              </div>
            </div>
          </template>
          <template #empty>
            Geen producten gevonden. Voeg producten toe via <router-link to="/producten">Alle producten</router-link>
          </template>
          <template #loading>
            Bezig met laden van bestellijst, even geduld aub...
          </template>
        </DataTable>
      </div>
    </div>
  </div>

  <OrderListDialog ref="orderListDialog"/>
  <AreYouSure ref="areYouSure"/>
</template>

<script>
import Translate from '../mixins/Translate.js'
import OrderListDialog from '../components/OrderListDialog'
import AreYouSure from '../components/AreYouSure'

export default {
  mixins: [Translate],
  data() {
    return {
      orderList: {},
      expandedRowGroups: [],
      editingCellRows: {}
    }
  },
  mounted() {
    this.getOrderList()
  },
  watch: {
    $route(route) {
      if (route.name === 'orderList') {
        this.getOrderList()
      }
    }
  },
  computed: {
    newOrderDisabled() {
      let isDisabled = true
      this.orderList.items.forEach(orderList => {
        if (orderList.quantity > 0) {
          isDisabled = false
          return isDisabled
        }
      })

      return isDisabled
    }
  },

  methods: {
    getOrderList() {
      this.$root.orderListService.getOrderList(this.$route.params.id).then(data => {
        this.orderList = data
        this.orderList.items.forEach((item) => {
          this.expandedRowGroups.push(item.product.category)
        })
      })
    },
    editOrderList() {
      this.$refs.orderListDialog.orderList = this.orderList
      this.$refs.orderListDialog.orderListDialog = true
    },
    onQuantityEdit(newValue, props) {
      if (!this.editingCellRows[props.index]) {
        this.editingCellRows[props.index] = {...props.data}
      }

      this.editingCellRows[props.index][props.column.props.field] = newValue

      this.$root.orderListService.updateQuantity({
        id: props.data.id,
        quantity: newValue
      }).then(() => {
        this.$toast.add({severity: 'success', summary: 'Aantal aangepast', detail: `Voor ${props.data.product.name}`, life: 2000});
      }).catch(() => {
        this.$toast.add({severity: 'error', summary: 'Error', detail: 'Er ging wat mis'})
      })
    },
    newOrder() {
      this.$root.orderService.newOrder(this.orderList).then(() => {
        this.$router.push({ name: 'orders' })
      }).catch(() => {
        this.$toast.add({severity: 'error', summary: 'Error', detail: 'Bestelling niet gelukt'})
      })
    },
    async confirmDeleteOrderList() {
      const ok = await this.$refs.areYouSure.show(`Weet je zeker dat je <b>${this.orderList.name}</b> wilt verwijderen?`)

      if (ok) {
        await this.$root.orderListService.deleteOrderList(this.orderList)
        this.$root.updateOrderList()

        await this.$router.push({ name: 'dashboard' })
      }
    },
    async confirmDeleteOrderListItem(orderListItem) {
      const ok = await this.$refs.areYouSure.show(`Weet je zeker dat je <b>${orderListItem.product.name}</b> wilt verwijderen?`)

      if (ok) {
        this.$root.orderListService.removeProductFromOrderList(orderListItem).then(() => {
          this.orderList.items = this.orderList.items.filter(val => val.id !== orderListItem.id);
          this.$toast.add({severity: 'success', summary: 'Product verwijderd', detail: `Het product is verwijderd`, life: 3000});
        }).catch(() => {
          this.$toast.add({severity: 'error', summary: 'Error', detail: 'Er ging wat mis'})
        })

      }
    }
  },
  components: {
    'OrderListDialog': OrderListDialog,
    'AreYouSure': AreYouSure,
  }
}
</script>

<style lang="scss" scoped>
.button-edit {
  vertical-align: middle;
}
</style>
