<template>
  <Dialog v-model:visible="showDialog" :style="{width: '450px'}" header="Confirm" :modal="true">
    <div class="confirmation-content">
      <i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem"/>
      <span v-html="content"></span>
    </div>
    <template #footer>
      <Button label="Nee" icon="pi pi-times" class="p-button-text" @click="_cancel"/>
      <Button label="Ja" icon="pi pi-check" class="p-button-text" @click="_confirm"/>
    </template>
  </Dialog>
</template>

<script>
import Button from 'primevue/button'
import Dialog from 'primevue/dialog'

export default {
  components: {
    Button,
    Dialog
  },
  data() {
    return {
      content: null,
      showDialog: false,
      resolvePromise: undefined,
      rejectPromise: undefined,
    }
  },
  methods: {
    show(content) {
      this.content = content
      this.showDialog = true

      return new Promise((resolve, reject) => {
        this.resolvePromise = resolve
        this.rejectPromise = reject
      })
    },
    _confirm() {
      this.showDialog = false
      this.resolvePromise(true)
    },
    _cancel() {
      this.showDialog = false
      this.resolvePromise(false)
    },
  }
}
</script>
<style lang="scss" scoped>
.confirmation-content {
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
